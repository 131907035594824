import { render, staticRenderFns } from "./GameSwiper.vue?vue&type=template&id=2fd3a172&scoped=true&"
import script from "./GameSwiper.vue?vue&type=script&lang=js&"
export * from "./GameSwiper.vue?vue&type=script&lang=js&"
import style0 from "./GameSwiper.vue?vue&type=style&index=0&id=2fd3a172&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fd3a172",
  null
  
)

export default component.exports