<template>
  <div class="wrapper">
    <div class="game_wrapper">
      <div class="hot hot_comWidth">
        <h2>遊戲儲值</h2>
        <div class="hot_wrapper">
          <a class="fr loginOut" @click="loginOut">登出</a>
          <div class="clear"></div>
          <div class="hot_con content">
            <game-store :curId="curId"></game-store>
          </div>
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import GameStore from "./GameStore";

export default {
  name: "GameStorecenter",
  inject: ["reload"],
  components: {
    GameStore
  },
  data: function() {
    return {
      tabList: ["儲值遊戲"],
      curId: 0,
      options: {
        isLoading: false
      },
      defaultImgSrc: "/public/imgs/sub_banner.jpg"
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleStoreNow() {
      this.curId = 0;
    },
    loginOut() {
      this.options.isLoading = true;
      let datas = datas;
      this.$router.push({ path: "/login" });
      this.$toast("已登出");
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      this.reload();
    }
  }
};
</script>

<style lang="stylus" scoped>
.game_wrapper {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 130px;
    /** background: pink; **/
    .pic_img{
        display: block;
        overflow: hidden;
        width 1920px
        height 0
        padding-bottom 34.84375%
        background #ccc
    }
    .hot_comWidth {
        width: 1200px;
        margin: 0 auto;
    }

    .hot {
        margin-top: 40px;
        .loginOut{
            display: inline-block;
            width: 80px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            color:#ff7a07;
            background: #fff;
            border:1px solid #ff7a07;
            box-sizing: border-box;
            position: absolute;
            top: 17px;
            right: 30px;
            border-radius: 3px;
            &:hover{
                color: #fff;
                background: #ff7a07;
                border-radius: 3px;
                transition: all .9s;
                cursor:pointer;
            }
        }

        &>h2 {
            display: block;
            position: relative;
            width: 100%;
            text-indent: 30px;
            font-size: 30.24px;
            color: #171717;
            font-weight: 500;
            text-align: left;
            &::before{
                content: '';
                position: absolute;
                width: 10.52px;
                height: 27.86px;
                left: 0;
                top: 50%;
                margin-top: -12px;
                background: #ff7a07
            }
        }
    }
}

.hot_wrapper {
    position:relative;
    width: 900px;
    margin: 0 auto;
    ul.hot_tab {
        li {
            float: left;
            width: 205.53px;
            height: 41px;
            line-height: 41px;
            margin-left: 50 px;
            text-align: center;
            font-size: 20.16px;
            color: #ff7a07;
            background: #fff;
            border-radius: 2px;
            border: 2px solid #f5854e;
            cursor: pointer;

            &.active {
                color: #ffffff;
                background: #ff7a07;
            }

            &:nth-child(1) {
                margin-left: 0;
            }
        }
    }

    .hot_con {
        margin-top: 34px;
    }
}
</style>
