<template>
  <div class="game_swiper_wrapper">
    <div class="swiper">
      <swiper :options="swiperOption" v-if="swiperShow">
        <swiper-slide
          v-for="(item, index) in userRegisteredGamesList"
          :key="index"
          :class="{ active: gameId == item.id }"
        >
          <div @click="handleGameStoreIndex(item.id, item.uuid, index)">
            <img :src="item.icon_img" width="100%" class="swiper_img" />
            <span class="game_name ep-one">{{ item.name }}</span>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameSwiper",
  props: {
    curId: Number,
    gameId: Number,
    userRegisteredGamesList: Array
  },
  data() {
    return {
      swiperOption: {
        grabCursor: true,
        slidesPerView: 4,
        spaceBetween: 30,
        loop: false,
        navigation: {
          prevEl: ".login_box .swiper-button-prev",
          nextEl: ".login_box .swiper-button-next"
        }
      },
      swiperId: 0
    };
  },
  computed: {
    swiperShow: function() {
      return this.userRegisteredGamesList.length;
    }
  },
  watch: {
    gameId: function() {
      return this.swiperId;
    }
  },
  mounted() {
    this.swiperId = this.gameId;
  },
  methods: {
    handleGameStoreIndex: function(gameId, uuid, index) {
      this.swiperId = gameId;
      this.$emit("getGameStoreSwiperIndex", gameId, uuid, index);
    }
  }
};
</script>

<style lang="stylus" scoped>
.game_swiper_wrapper >>> .active{
    border: 4px solid #ff7a07;
    box-sizing: border-box
}
.swiper-slide{
    box-sizing: border-box
}
.game_swiper_wrapper >>> .active::after{
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 26px;
    height: 26px;
    background: url('../assets/imgs/selected.png') no-repeat;
    background-size:100% 100%;
}
.game_swiper_wrapper >>> .swiper-button-prev{
    color: #fff;
    left: -88px;
    width: 43px;
    height: 63px;
    top: 56px;
    background:url('../assets/imgs/prev.png') no-repeat center;
    background-size: 100% 100%;
    cursor pointer
}
.game_swiper_wrapper >>> .swiper-button-next{
    color: #fff;
    right: -88px;
    width: 43px;
    height: 63px;
    top: 56px;
    transform: rotate(180deg)
    background:url('../assets/imgs/prev.png') no-repeat center;
    background-size: 100% 100%;
    cursor pointer
}
.swiper-wrapper{
    width 519px
}
.game_swiper_wrapper {
    position relative
    z-index 0
    width 100%
    height 100%
    .game_name{
        display block
        width 90px
        margin 0 auto
        text-align center
        border-top 1px dashed #5d5d5d
        font-size 15px
    }
}
</style>
